// import 'reset-css';
import './src/styles/global.css';
import '@tiney/web-kit/src/theme/global.css';

import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient} contextSharing={true}>
    {element}
  </QueryClientProvider>
);

export const onRouteUpdate = ({ location }) => {
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'page_view',
      page_path: location.pathname,
    });
  }
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 50);

  return false;
};

/*
 * Prevents hot reload jumping to top on CSS update
 */
let activeEnv = process.env.ACTIVE_ENV;

if (!activeEnv) {
  activeEnv = 'develop';
}
